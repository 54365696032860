<template>
  <div class="clear-both lg:grid lg:grid-cols-2">
    <div class="md:pr-1 mb-4">
      <label for="voornaam" class="text-md block mb-1 font-bold">
        Voornaam {{ suffix }}
        <span class="pl-1 font-bold text-red-500">*</span>
      </label>
      <input v-model="klant.firstname" type="text" id="voornaam" placeholder="Voornaam..." required="required" class="w-full ro" />
    </div>
    <div class="md:pl-1 mb-4">
      <label for="naam" class="text-md block mb-1 font-bold">
        Naam {{ suffix }}
        <span class="pl-1 font-bold text-red-500">*</span>
      </label>
      <input v-model="klant.lastname" type="text" id="naam" placeholder="Naam..." required="required" class="w-full ro" />
    </div>
    <div class="md:pr-1 mb-4 md:mb-0">
      <label for="phone" class="text-md block mb-1 font-bold">
        GSM Nummer {{ suffix }}
        <span class="pl-1 font-bold text-red-500">*</span>
      </label>
      <input v-model="klant.phone" type="text" id="phone" placeholder="GSM Nummer..." required="required" class="w-full ro" />
    </div>
    <div class="md:pl-1">
      <label for="email" class="text-md block mb-1 font-bold">
        E-mail {{ suffix }}
        <span class="hidden md:inline">adres</span>
        <span class="pl-1 font-bold text-red-500">*</span>
      </label>
      <input v-model="klant.email" type="text" id="email" placeholder="E-mail..." required="required" class="w-full ro" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    suffix: String,
  },
  setup(props, { emit }) {
    const klant = ref(props.modelValue || {})
    watch(
      () => klant.value,
      (val) => emit('update:modelValue', val),
    )
    return { klant }
  },
}
</script>
