<template>
  <div class="google-directions h-8 my-1 text-center font-bold bg-baioro-black px-4 py-1" v-if="loading">
    <span>Route berekenening {{ id }} ...</span>
  </div>
</template>

<script>
import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { latest as sharedLib } from '@/assets/taxiboeken-shared'
import useGoogleMaps from '@/hooks/useGoogleMaps'
import md5 from 'md5'
import { computed, ref, watch, onMounted } from 'vue'

const getCacheKey = (value) => {
  const tmp = typeof value === 'object' ? JSON.stringify(value) : String(value)
  return md5(tmp.toLowerCase())
}

const googleInputToString = (googleInputResult) => {
  if (typeof googleInputResult === 'string') {
    return googleInputResult
  }
  if (googleInputResult.place_id || googleInputResult.placeId) {
    return { placeId: googleInputResult.place_id || googleInputResult.placeId }
  }
  if (googleInputResult.location) {
    return { location: googleInputResult.location }
  }
  if (googleInputResult.geocode || googleInputResult.coords) {
    const coords = googleInputResult.geocode || googleInputResult.coords
    return { lat: coords.lat, lng: coords.lng }
  }
  return googleInputResult
}

const waypointMapper = (point) => {
  if (typeof point === 'string') {
    return {
      location: point,
      stopover: true,
    }
  }
  return {
    location: googleInputToString(point),
    stopover: point.stopover || point.stopover === false ? point.stopover : true,
  }
}

export default {
  props: {
    // GoogleInput.vue array of those results
    adressen: {
      type: Array,
      required: true,
    },
    // Unique ID
    id: {
      type: String,
      required: true,
    },
    debug: Boolean,
  },
  emits: ['error', 'directions'],
  setup(props, { emit }) {
    const promises = ref({})
    const loading = ref(false)
    const timeout = ref(null)

    const directions = computed(() => {
      const obj = {
        origin: props.adressen[0] || {},
        destination: props.adressen.slice(-1)[0] || {},
        waypoints: props.adressen.slice(1, -1) || [],
        fixed: 'destination',
      }
      if (obj.waypoints.length || (obj.origin && obj.origin.is_luchthaven)) {
        obj.fixed = 'origin'
      }
      return obj
    })

    const cacheKey = computed(() => getCacheKey(directions.value))

    const directionRequest = computed(() => {
      const DIRECTIONS = directions.value
      const result = {
        origin: googleInputToString(DIRECTIONS.origin),
        destination: googleInputToString(DIRECTIONS.destination),
        travelMode: 'DRIVING',
        drivingOptions: {
          departureTime: new Date(2533770000000),
          trafficModel: 'optimistic',
        },
        provideRouteAlternatives: true,
        optimizeWaypoints: false,
        avoidHighways: false,
        avoidTolls: false,
        waypoints: [],
      }
      const hasWaypoints = DIRECTIONS.waypoints && DIRECTIONS.waypoints.length > 0
      if (hasWaypoints) {
        result.waypoints = DIRECTIONS.waypoints
          .slice(0)
          .filter((el) => !!el && String(el).length > 0)
          .map(waypointMapper)
      }
      return result
    })

    const getExtraFromResponse = ({ result, route, response }) => {
      return {
        id: props.id,
        distance: result.meters,
        duration: result.time,
        warnings: route.legs[0].warnings,
      }
    }

    const getDirections = async () => {
      window.googleCache = window.googleCache || {}
      if (window.googleCache[cacheKey.value]) {
        const cachingResult = JSON.parse(decompressFromUTF16(window.googleCache[cacheKey.value]))
        cachingResult.is_cache = true
        emit('directions', cachingResult)
        return cachingResult
      }

      if (promises.value[cacheKey.value]) {
        return promises.value[cacheKey.value]
      }

      promises.value[cacheKey.value] = new Promise((resolve) => {
        window.directionsService.route(directionRequest.value, (googleResponse, googleStatus) => {
          const STATUS_NOT_OK = googleStatus !== 'OK'
          if (props.debug) {
            console.log(`[Direction Request Debug Response]`, googleResponse, googleStatus)
          }
          if (STATUS_NOT_OK) {
            console.warn(`[Direction Request NOT OK]`, googleResponse, googleStatus)
            emit('error', {
              result: null,
              route: null,
              response: googleResponse,
              mappedResponse: null,
            })
            delete promises.value[cacheKey.value]
            return resolve(null)
          }
          if (googleResponse.routes && googleResponse.routes.length > 0) {
            const result = sharedLib.getShortestRoutes(googleResponse.routes)
            if (result.shortest) {
              let calculation = {
                result,
                route: result.shortest,
                response: googleResponse,
              }
              const extra = getExtraFromResponse(calculation)
              calculation = Object.assign({}, calculation, extra)
              calculation.is_cache = false
              emit('directions', calculation)
              window.googleCache[cacheKey.value] = compressToUTF16(JSON.stringify(calculation))
              return resolve(calculation)
            }
          }

          return resolve(null)
        })
      })

      return promises.value[cacheKey.value]
    }

    const calculate = async () => {
      const google = await useGoogleMaps()
      if (!google) return

      window.directionsService = window.directionsService || new google.maps.DirectionsService()

      try {
        clearTimeout(timeout.value)
        timeout.value = setTimeout(() => {
          getDirections()
          timeout.value = null
          setTimeout(() => (loading.value = false), 360)
        }, 350)
      } catch (e) {
        console.error(e)
        getDirections()
      }

      loading.value = true
    }

    onMounted(calculate)
    watch(
      () => directions.value,
      () => {
        if (!directions?.value?.origin?.place_id) {
          return
        }
        if (!directions?.value?.destination?.place_id) {
          return
        }
        calculate()
      },
    )

    return { loading }
  },

  // emitMap(route, result, response = false) {
  //   const addresses = route.legs
  //   .reduce((prev, curr) => {
  //     prev.push(curr.end_address);
  //     return prev;
  //   }, [route.legs[0].start_address])
  //   .filter((el, index, self) => {
  //     return self.indexOf(el) === index;
  //   });

  //   this.$set(this.mapinfo, 'ophaal', addresses[0]);
  //   this.$set(this.mapinfo, 'waypoints', addresses.slice(1, addresses.length - 1));
  //   this.$set(this.mapinfo, 'bestemming', addresses[addresses.length - 1]);
  //   this.$set(this.mapinfo, 'distance', result.meters);
  //   this.$set(this.mapinfo, 'duration', result.time);
  //   this.$set(this.mapinfo, 'warnings', route.warnings || route.legs[0].warnings || []);
  //   if (response && response.geocoded_waypoints) {
  //     const mappedAdressen = addresses
  //       .slice(0)
  //       .map((adres, index) => {
  //         const obj = response.geocoded_waypoints[index] || {};
  //         return {
  //           place_id: obj.place_id,
  //           adres,
  //           is_luchthaven: !!(this.findLuchthaven(adres) || (obj.types && obj.types.indexOf('airport') > -1)),
  //         };
  //       });
  //     this.$set(this.mapinfo, 'adressen', mappedAdressen);
  //     this.$set(this.mapinfo,
  //       'is_ophaal_luchthaven',
  //       mappedAdressen[0].is_luchthaven
  //         || ((response.geocoded_waypoints[0].types || []).indexOf('airport') > -1),
  //     );
  //     this.$set(this.mapinfo,
  //       'is_bestemming_luchthaven',
  //       mappedAdressen[mappedAdressen.length - 1].is_luchthaven
  //         || ((response.geocoded_waypoints[response.geocoded_waypoints.length - 1].types || []).indexOf('airport') > -1),
  //     );
  //   }
  //   if (this.map && route.bounds) this.mapEl.fitBounds(route.bounds);
  //   this.$nextTick(() => {
  //     try {
  //       window.googleCache[this.cache] = compressToUTF16(JSON.stringify(this.mapinfo));
  //     } catch (err) {
  //       console.error('Directions GoogleCache', err);
  //     }
  //     this.minimumZoom(this.mapEl);
  //     this.$emit('mapinfo', this.mapinfo);
  //     this.show = true;
  //   });
  // },
}
</script>
