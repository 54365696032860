<template>
  <div class="card flex flex-col lg:flex-row text-baioro-black items-center lg:justify-between px-8">
    <div class="flex flex-grow flex-col lg:flex-row lg:items-center" :class="{ 'p-6': !pricing.src }">
      <img v-if="pricing.src" :src="pricing.src" :alt="`Baioro vervoert je met een ${pricing.car}`" class="h-24 my-2" />
      <div :class="{ 'lg:pl-8 flex-1': pricing.src }">
        <h2 class="font-bold text-2xl">{{ pricing.car }}</h2>
        <p class="text-sm" v-if="pricing.ENUM_CAR">(of vergelijkbaar)</p>
        <p class="text-sm" v-else-if="pricing.info">({{ pricing.info }})</p>
      </div>
    </div>
    <div class="flex flex-row items-center py-4 lg:py-0">
      <div class="font-bold px-4">&euro;&nbsp;{{ pricing.prijs }}</div>
      <button type="submit" class="py-2" @click="$emit('input', $event)">Nu boeken</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pricing: {
      type: Object,
      required: true,
    },
  },
  setup() {},
}
</script>
