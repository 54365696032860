<template>
  <h1 class="text-2xl font-bold text-center mb-4 form--titel">Bereken online de prijs van jouw vervoer</h1>
  <UIForm />
</template>

<script>
import UIForm from '@/components/UI/Form/Index.vue'

export default {
  name: 'Home',
  components: {
    UIForm,
  },
}
</script>

<style lang="scss" scoped>
.form--titel {
  text-shadow: 2px 2px #1d0c50;
  text-shadow: 1.5px 1.5px #1d0c50;
}
</style>
