<template>
  <div class="w-full flex flex-col justify-center min-h-screen x-4 sm:px-6 lg:px-8 py-6 animate__animated animate__fadeIn animate__zoomIn animate__fast animate__delay-200ms">
    <div>
      <img class="h-32 mx-auto" src="https://www.baioro.be/wp-content/uploads/2019/06/Baioro-logo-neg-RGB.png" alt="" />
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
}
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fefefe;
  @apply bg-baioro-black;
}

body::before {
  content: '';
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.75;
  z-index: -1;
  position: fixed;
  background-image: url(https://media.vrbo.com/lodging/40000000/39840000/39833500/39833406/cf1a08d3.c10.jpg);
  background-size: cover;
  background-position: center;
}
</style>
