// eventBus.js
import TinyEmitter from 'tiny-emitter'
export const createEventBus = () => new TinyEmitter()
export const instance = createEventBus()

export const events = {
  $on: (...args) => instance.on(...args),
  $once: (...args) => instance.once(...args),
  $off: (...args) => instance.off(...args),
  $emit: (...args) => instance.emit(...args),
}

export default () => events
