<template>
  <div :key="String(modelValue)" class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1 text-gray-800 cursor-pointer" id="aantal-bagage" style="max-width: 140px">
    <button
      type="button"
      @click="$emit('update:modelValue', modelValue - (modelValue <= min ? 0 : 1))"
      class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none text-gray-600"
    >
      <span class="m-auto text-2xl font-thin">&minus;</span>
    </button>
    <select
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="text-center appearance-none outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none"
    >
      <option v-for="n in max - min + 1" :key="n" :value="n + min - 1" :selected="modelValue === n + min - 1">{{ n + min - 1 }}</option>
    </select>
    <button
      @click="$emit('update:modelValue', modelValue + (modelValue >= max ? 0 : 1))"
      type="button"
      class="bg-gray-300 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer text-gray-600"
    >
      <span class="m-auto text-2xl font-thin">+</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    max: {
      type: [String, Number],
      default: 999,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  setup() {},
}
</script>
