<template>
  <form @submit.prevent="$emit('submit', form)" class="bg-[#FEFEFE99] border-t-4 border-t-baioro-gold p-4 text-baioro-black">
    <div class="container mx-auto px-2">
      <h2 class="text-3xl font-bold">Online reserveren</h2>
      <p class="text-md">We hebben nog een aantal gegevens nodig.</p>
    </div>
    <div class="container mx-auto px-2 mt-4" style="font-size: 85%">
      <p class="block font-bold pb-1">Gegevens contactpersoon</p>
      <div :key="form.reiziger.actief" class="w-full border-1 border-gray-300 border-l-3 border-l-kevin p-3 bg-baioro-black text-white">
        <PartKlant v-model="form.klant" />
        <div class="pt-6 clear-both">
          <input v-model="form.reiziger.actief" type="checkbox" id="reiziger" class="appearance-none leading-normal cursor-pointer h-5 w-5 text-baioro-gold" />
          <label for="reiziger" class="inline ml-2 cursor-pointer text-sm font-semibold">
            <span class="pr-4">Boek je deze rit voor iemand anders (andere reiziger)?</span>
          </label>
        </div>
        <PartKlant v-if="form.reiziger.actief" suffix="(Reiziger)" v-model="form.reiziger" class="mt-2 border border-baioro-primary p-4" />
      </div>

      <p class="block font-bold mt-6 pb-1">Gegevens vluchten</p>
      <div class="flex gap-2 w-full order-1 border-gray-300 border-l-3 border-l-kevin p-3 bg-baioro-black text-white">
        <div class="inline-block w-full lg:w-1/2 float-left">
          <label for="vertrek_vluchtnummer" class="text-xs block font-bold mb-1">
            Vluchtnummer
            <span v-if="form.is_retour">heenreis</span>
            (indien van toepassing)
          </label>
          <input
            v-model="form.heenreis.vlucht"
            type="text"
            id="vertrek_vluchtnummer"
            :placeholder="form.is_retour ? 'Vluchtnummer heenreis...' : 'Vluchtnummer...'"
            required="required"
            class="w-full ro"
          />
        </div>
        <div class="inline-block w-full lg:w-1/2 float-left" v-if="form.is_retour">
          <label for="retour_vluchtnummer" class="text-xs block font-bold mb-1">Vluchtnummer retour (indien van toepassing)</label>
          <input v-model="form.retour.vlucht" type="text" id="retour_vluchtnummer" placeholder="Vluchtnummer retour..." required="required" class="w-full ro" />
        </div>
        <div class="clear-both"></div>
      </div>

      <!-- <p class="block font-bold mt-6 pb-1">Extra diensten</p>
      <div class="w-full border-1 border-gray-300 border-l-3 border-l-kevin p-3 bg-baioro-black text-white">
        <div class="inline-block w-full mb-4 md:mb-0 lg:w-1/2 float-left">
          <div class="mb-2">
            <input
              v-model="form.is_wakeup_call"
              id="wakeup-call"
              type="checkbox"
              class="bg-gray-200 border-1 border-gray-600 form-checkbox cursor-pointer h-5 w-5 text-baioro-gold"
            />
            <label for="wakeup-call" class="inline ml-2 font-semibold cursor-pointer">
              <span class="pr-4">Wake-up call [+5 EUR]</span>
              <small class="ml-8 block mt-1 bg-blue-100 text-blue-700 border-1 border-blue-500 px-2 py-1">Laat je opbellen door onze chauffeur voor een wake-up call</small>
            </label>
          </div>
          <div>
            <input
              v-model="form.is_annulatie_verzekering"
              id="annulatie-verzekering"
              type="checkbox"
              class="bg-gray-200 border-1 border-gray-600 form-checkbox cursor-pointer h-5 w-5 text-baioro-gold"
            />
            <label for="annulatie-verzekering" class="inline ml-2 font-semibold cursor-pointer">
              <span class="pr-4">Annulatieverzekering [+5 EUR]</span>
            </label>
          </div>
        </div>
        <div class="clear-both"></div>
        <div>
          <p class="ml-7 block mt-1 border-1 px-2 py-1 border border-baioro-primary">
            Als uw inkomende vlucht / trein / schip wordt geannuleerd en u belt om ons op elk moment vóór de oorspronkelijke geplande vertrektijd te informeren.
            <br />
            We zullen dan ofwel de betreffende transferservice kosteloos opnieuw plannen (afhankelijk van beschikbaarheid) of u een volledige terugbetaling geven voor de
            betreffende transfer.
          </p>
        </div>
      </div> -->

      <p class="block font-bold mt-6 pb-1">Bagage en kinderen</p>
      <div class="w-full bg-baioro-black text-white">
        <div class="flex lg:flex-row gap-2 mt-1">
          <div class="inline-block mb-4 md:mb-0 lg:pl-1">
            <div class="border-1 border-gray-300 border-l-3 border-l-kevin p-3">
              <label for="aantal-handbagage" class="text-md block mb-2">
                <span class="font-bold mr-1">Grote bagage</span>
                <small>(1 bagage per persoon inbegrepen)</small>
              </label>
              <SelectNumber v-model="form.heenreis.bagage" @input="form.retour.bagage = !form.is_retour ? 0 : $event" :min="0" :max="50" />
            </div>
          </div>
          <div class="inline-block mb-4 md:mb-0 lg:pl-1">
            <div class="border-1 border-gray-300 border-l-3 border-l-kevin p-3">
              <label for="aantal-handbagage" class="text-md block mb-2">
                <span class="font-bold mr-1">Handbagage</span>
                <small>(1 handbagage per persoon inbegrepen)</small>
              </label>
              <SelectNumber v-model="form.heenreis.handbagage" @input="form.retour.handbagage = !form.is_retour ? 0 : $event" :min="0" :max="50" />
            </div>
          </div>
        </div>

        <div class="w-full border-1 border-gray-300 border-l-3 border-l-kevin p-3">
          <label class="text-md block font-bold mb-2">Veiligheid voor kinderen</label>
          <div class="">
            <input v-model="pageValues.kinderen" id="kinderen" type="checkbox" class="bg-gray-200 border-1 border-gray-600 form-checkbox cursor-pointer h-5 w-5 text-baioro-gold" />
            <label for="kinderen" class="inline ml-2 cursor-pointer">
              <span class="pr-4">Reizen er kinderen mee?</span>
            </label>
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mt-2" v-if="pageValues.kinderen">
            <div class="inline-block mb-4 md:mb-0 lg:pl-1 border border-baioro-primary" v-for="o in kinderOpties" :key="o.key">
              <div class="border-1 border-gray-300 border-l-3 border-l-kevin p-3">
                <label for="aantal-handbagage" class="text-md block mb-2">
                  <span class="font-bold mr-1">{{ o.label }}</span>
                  <small>(Gratis inbegrepen)</small>
                </label>
                <SelectNumber v-model="form.heenreis[o.key]" @update:modelValue="form.retour[o.key] = !form.is_retour ? 0 : $event" :min="0" :max="50" />
              </div>
            </div>
          </div>

          <div class="clear-both"></div>
        </div>
      </div>

      <p class="block font-bold mt-6 pb-1">Betaalmethode</p>
      <div class="w-full border-1 border-gray-300 border-l-3 border-l-kevin bg-baioro-black text-white p-3">
        <div class="mb-2">
          <input
            v-model="form.betaalmethode"
            @input="form.facturatie.actief = $event.target.value === 'FACTUUR'"
            value="ONLINE"
            type="radio"
            id="betaal-online"
            name="betaalmethode"
            class="bg-gray-200 border-1 border-gray-600 form-radio cursor-pointer h-5 w-5 text-baioro-gold"
          />
          <label for="betaal-online" class="inline ml-2 cursor-pointer">
            <span class="pr-4">Veilig online betalen</span>
          </label>
        </div>
        <div class="mb-2">
          <input
            v-model="form.betaalmethode"
            @input="form.facturatie.actief = $event.target.value === 'FACTUUR'"
            value="FACTUUR"
            type="radio"
            id="betaal-factuur"
            name="betaalmethode"
            class="bg-gray-200 border-1 border-gray-600 form-radio cursor-pointer h-5 w-5 text-baioro-gold"
          />
          <label for="betaal-factuur" class="inline ml-2 cursor-pointer">
            <span class="pr-4">Op factuur betalen (Zakelijke klanten met BTW nummer)</span>
          </label>
          <input
            v-if="form.facturatie.actief"
            type="text"
            name="factuur_btw"
            placeholder="BTW Nummer ..."
            v-model="form.facturatie.BTW"
            class="bg-gray-200 border-1 border-gray-600 text-baioro-gold block input w-full mt-1 py-2"
          />
        </div>
        <!---->
        <!-- <div>
          <input type="radio" id="cash" name="betaalmethode" class="bg-gray-200 border-1 border-gray-600 form-radio cursor-pointer h-5 w-5 text-baioro-gold" />
          <span class="inline ml-2 cursor-pointer"><span class="pr-4">Cash aan de chauffeur betalen</span></span>
        </div> -->
      </div>

      <p class="block font-bold mt-6 pb-1">Voorwaarden</p>
      <div class="w-full border-1 border-gray-300 border-l-3 border-l-kevin bg-baioro-black text-white p-3">
        <div class="mb-2">
          <input
            v-model="form.check_voorwaarden"
            id="voorwaarden"
            type="checkbox"
            class="bg-gray-200 border-1 border-gray-600 form-checkbox cursor-pointer h-5 w-5 text-baioro-gold"
          />
          <label for="voorwaarden" class="inline ml-2 cursor-pointer">
            <span class="pr-4">
              Ga je akkoord met onze
              <a href="/voorwaarden" class="text-baioro-gold hover:underline" target="_blank">Algemene voorwaarden</a>
              en
              <a href="/privacy-policy#privacy" class="text-baioro-gold hover:underline" target="_blank">Privacy Policy</a>
              ?
              <span class="pl-1 font-bold text-red-500">*</span>
            </span>
          </label>
        </div>
        <div>
          <input type="checkbox" id="nieuws" v-model="form.check_nieuwsbrief" class="bg-gray-200 border-1 border-gray-600 form-checkbox cursor-pointer h-5 w-5 text-baioro-gold" />
          <label for="nieuws" class="inline ml-3 cursor-pointer">
            <span class="pr-4">Wil je onze maandelijkse nieuwsbrief en promoties ontvangen die van toepassing zijn op jouw regio?</span>
          </label>
        </div>
      </div>

      <p class="block font-bold mt-6 pb-1">Extra opmerkingen</p>
      <div class="w-full border-1 border-gray-300 border-l-3 border-l-kevin bg-baioro-black p-3">
        <label for="opmerking" class="text-md text-white block mb-1 font-bold">Opmerkingen</label>
        <textarea v-model="form.opmerking" id="opmerking" placeholder="Eventuele opmerkingen..." rows="3" class="w-full ro" style="min-height: 36px"></textarea>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="bg-blue-100 border-t-1 border-t-blue-300 border-b-1 border-b-blue-300 my-6 px-6 lg:px-0">
      <div class="container mx-auto px-2 py-6" style="max-width: 850px">
        <div class="w-full lg:w-1/2 lg:inline-block lg:float-left">
          <h2 class="block text-xl font-bold mb-2">Uw bestelling</h2>
          <table class="w-full block font-bold text-left">
            <tbody>
              <!---->
              <!---->
              <!---->
              <tr class="text-lg mt-2">
                <th class="align-top">Totaal prijs:</th>
                <td class="pl-4 text-green-600">{{ Number(`${option.prijs}`).toFixed(2) }} EUR</td>
              </tr>
            </tbody>
          </table>
          <!---->
          <!---->
        </div>
        <div class="w-full lg:w-1/2 lg:inline-block lg:float-left text-left lg:text-right">
          <ul class="inline-block text-left py-2 text-md">
            <li class="block pb-2">
              <IconCheck class="fill-baioro-gold h-4 w-4 inline mr-2"></IconCheck>
              <span class="pl-1 align-middle">Veilig</span>
            </li>
            <li class="block pb-2">
              <IconCheck class="fill-baioro-gold h-4 w-4 inline mr-2"></IconCheck>
              <span class="pl-1 align-middle">Betrouwbaar</span>
            </li>
            <li class="block pb-2">
              <IconCheck class="fill-baioro-gold h-4 w-4 inline mr-2"></IconCheck>
              <span class="pl-1 align-middle">24/7 telefonisch bereikbaar</span>
            </li>
          </ul>
        </div>
        <div class="clear-both"></div>
      </div>
    </div>
    <div class="container mx-auto pb-6">
      <div class="w-full text-center">
        <!---->
        <button @click.prevent="$emit('submit', form)" class="btn green text-xl py-3 px-8 w-full max-w-xs" type="submit">Ja! Reserveer deze rit!</button>
        <!---->
      </div>
    </div>
  </form>
</template>

<script>
// eslint-disable-next-line
import { computed, ref, watch } from 'vue'
import SelectNumber from './SelectNumber.vue'
import PartKlant from './PartKlant.vue'
import IconCheck from './../IconCheck.vue'

export default {
  props: {
    option: Object,
    modelValue: Object,
  },
  components: {
    SelectNumber,
    PartKlant,
    IconCheck,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const form = ref(props.modelValue || props.option?.form || {})
    const kinderOpties = [
      { key: 'kinderzitjes', label: 'Kinderzitjes - Verhoogkussen' },
      { key: 'kinderstoelen', label: 'Kinderstoelen - Met rugteun' },
      { key: 'maxicosis', label: 'Maxi cosi - Voor je baby' },
    ]
    const pageValues = ref({
      kinderen: false,
    })
    watch(
      () => form.value,
      () => emit('update:modelValue', form.value),
    )
    return { form, kinderOpties, pageValues }
  },
}
</script>
