import { create } from 'axios'
import { events } from './useEvents'

const axios = create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://backend.luchthavenvervoer-kevin.be/api' : 'http://localhost:3333/api',
})

const axiosDataJson = (res) => {
  if (typeof res.data === 'object') {
    return res
  }
  let resData = res.data || res
  if (typeof resData === 'string') {
    try {
      resData = JSON.parse(resData)
    } catch (e) {
      /* Nothing */
    }
  } else {
    resData = resData.body || resData
    if (typeof resData === 'string') {
      try {
        resData = JSON.parse(resData)
      } catch (e) {
        /* Nothing */
      }
    }
  }
  if (typeof resData === 'object') {
    return Object.assign({}, res, { data: resData })
  }
  return res
}

const removeBodySubmitting = () => {
  if (typeof window === 'undefined') return
  clearTimeout(window.BODY_CLASS_SUBMITTING_TIMEOUT)
  window.BODY_CLASS_SUBMITTING_TIMEOUT = setTimeout(() => {
    document.body.classList.remove('submitting')
  }, 360)
}

const addBodySubmitting = () => {
  if (typeof window === 'undefined') return
  document.body.classList.add('submitting')
}

axios.interceptors.request.use(
  (config) => {
    addBodySubmitting()
    return config
  },
  (error) => {
    removeBodySubmitting()
    events.$emit('axios-error', error)
    events.$emit('api-error', error)
    return Promise.reject(error)
  },
)

// @TODO: Test deze logica (backend errors formulieren) wanneer geimplementeerd in cloudplatform
const backendErrorsHandler = () => {
  /* (res, _parsed = null) => {
  /*
  const parsed = typeof _parsed === 'object' ? _parsed : axiosDataJson(res)
  if (!parsed.success && parsed.error === BACKEND_ERROR_STRING) {
    Object.entries(parsed.data).forEach(([fieldName, errors]) => {
      events.$emit(`validation-error-${fieldName}`, errors)
    })
  }
  */
}

axios.interceptors.response.use(
  (res) => {
    removeBodySubmitting()
    events.$emit('axios-response', res)
    events.$emit('api-response', res)
    const parsed = axiosDataJson(res)
    const { redirect } = parsed.data || {}
    if (redirect) {
      window.location.href = redirect
    }
    return parsed
  },
  (error) => {
    // if (error?.response?.status === 401) {
    //   window.location.href = '/login?status=401'
    // }
    removeBodySubmitting()
    backendErrorsHandler(error.response)
    events.$emit('axios-error', error)
    events.$emit('api-error', error)
    if (typeof window?.alert !== 'undefined') {
      if (error?.response?.data?.validationErrors?.length) {
        window.alert(error.response.data.validationErrors.map((el) => el.error).join('\n'))
      } else if (error?.response?.data?.error) {
        window.alert(error.response.data.error)
      } else {
        window.alert(typeof error.toString === 'function' ? error.toString() : error.error || error.message || error.err || error)
      }
    }
    return Promise.reject(error)
  },
)

export default () => ({
  reservaties: {
    create: (values) => axios.post('/website/forms/reservatie-module', values),
  },
  axios,
})
