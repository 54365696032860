<template>
  <div class="pb-8 bg-transparent form--wrapper px-2 lg:px-1" :class="{ open: oplossingen.length }">
    <Step1 v-if="!showStep2" @submit="calculatePrice" @change="form.heenreis.datum = null" />
    <div class="flex flex-col gap-2 mt-8 animate__animated">
      <template v-if="oplossingen.length > 0 && !showStep2">
        <p class="font-bold text-white">Prijs</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-2 -mt-2">
          <FormPrice
            v-for="(item, index) in oplossingen"
            :key="JSON.stringify(item)"
            :pricing="item"
            @input="onOptionSelected(index)"
            class="animate__animated animate__fadeInDown"
            :class="`animate__delay-${index * 300}ms`"
          />
        </div>
      </template>
      <GoogleDirections
        v-if="form.heenreis.adressen?.length"
        :key="`h-${form.heenreis.adressen?.length}`"
        id="heenreis"
        :adressen="form.heenreis.adressen"
        @directions="onDirections($event)"
      />
      <GoogleDirections
        v-if="form.is_retour && form.retour.adressen?.length && retourAdressenVerschillend"
        :key="`r-${form.retour.adressen?.length}`"
        id="retour"
        :adressen="form.retour.adressen"
        @directions="onDirections($event)"
      />

      <Step2 v-if="showStep2" :option="selectedOption" v-model="form" @submit="onSubmit" />
      <Review v-if="oplossingen?.length > 0" class="mt-8" />
      <div class="text-center pt-4 form--notice text-base">
        <h4 class="font-bold">Problemen met het berekenen / reserveren?</h4>
        <p>
          Contacteer ons op het nummer
          <a class="bg-baioro-black px-2 text-baioro-gold hover:text-baioro-primary hover:underline" href="mailto:+32485660529">+32 485 66 05 29</a>
          <br />
          of mail ons op
          <a class="bg-baioro-black px-2 text-baioro-gold hover:text-baioro-primary hover:underline" href="mailto:drive@baioro.be">drive@baioro.be</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import FormPrice from './Price.vue'
import Review from './../Review.vue'
import { latest as sharedLib } from '@/assets/taxiboeken-shared'
import GoogleDirections from './GoogleDirections.vue'
import useApi from '@/hooks/useApi'
import clone from 'just-clone'

export default {
  components: {
    Step1,
    GoogleDirections,
    Step2,
    FormPrice,
    Review,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const routeName = computed(() => route.name)
    const api = useApi()

    // const oplossingen = ref([])
    const selectedOptionIndex = ref(null)
    const form = ref({
      klant: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
      },
      facturatie: {
        actief: false,
        BTW: '',
        naam: '',
        adres: '',
      },
      reiziger: {
        actief: false,
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
      },
      heenreis: {
        datum: null,
        tijd: null,
        adressen: [],
        warnings: [],
        personen: 0,
        kilometers: 0,
        seconden: 0,
        bagage: 0,
        handbagage: 0,
        maxicosis: 0,
        kinderstoelen: 0,
        kinderzitjes: 0,
      },
      retour: {
        datum: null,
        tijd: null,
        adressen: [],
        warnings: [],
        personen: 0,
        kilometers: 0,
        seconden: 0,
        bagage: 0,
        handbagage: 0,
        maxicosis: 0,
        kinderstoelen: 0,
        kinderzitjes: 0,
      },
      is_retour: false,
      is_wakeup_call: false,
      is_last_minute: false,
      is_prive_vervoer: false,
      is_annulatie_verzekering: false,
      check_voorwaarden: false,
      check_nieuwsbrief: false,
      opmerking: null,
      betaalmethode: 'ONLINE',
      extra_kost: 0,
      prijs: 0,
    })

    const oplossingen = computed(() => {
      const data = form.value

      if (!data?.heenreis?.datum || !data?.heenreis?.adressen?.length || !data?.heenreis?.kilometers) {
        return []
      }

      if (data?.is_retour && (!data?.retour?.datum || !data?.retour?.adressen?.length || !data?.retour?.kilometers)) {
        return []
      }

      const isLastMinute = sharedLib.isLastMinute(data.heenreis.datum)
      const baseOptions = {
        adressen: !data.is_retour ? [].concat([], data.heenreis.adressen) : [].concat([], data.heenreis.adressen, data.retour.adressen),
        adressen_heen: data.heenreis.adressen,
        adressen_retour: !data.is_retour ? [] : data.retour.adressen,
        kilometers_heen: data.heenreis.kilometers,
        kilometers_retour: !data.is_retour ? 0 : data.retour.kilometers,
        personen_heen: data.heenreis.personen,
        personen_retour: !data.is_retour ? 0 : data.retour.personen,
        bagage_heen: data.heenreis.bagage,
        bagage_retour: !data.is_retour ? 0 : data.retour.bagage,
        handbagage_heen: data.heenreis.handbagage,
        handbagage_retour: !data.is_retour ? 0 : data.retour.handbagage,
        kost_tol_heen: 0,
        kost_tol_retour: !data.is_retour ? 0 : 0,
        korting: 0,
        extra_kost: 0,
        prijs_verhoging: 0,
        is_retour: !!data.is_retour,
        isOphaalLuchthaven: data.heenreis.adressen[0].is_luchthaven,
        isBestemmingLuchthaven: data.heenreis.adressen[data.heenreis.adressen.length - 1].is_luchthaven,
        isPriveVervoer: data.is_prive_vervoer,
        isWakeUpCall: !!data.is_wakeup_call,
        isLastMinute,
        isBudget: false,
        isLuxe: false,
        isPrijsOnly: false,
        isSiteBoeking: true,
      }

      // const maxPersonen = Math.max(baseOptions.personen_heen, baseOptions.personen_retour)
      // const kostCoefficient = baseOptions.is_retour ? 2 : 1

      return [
        // {
        //   berekeningOptions: Object.assign({}, baseOptions, { extra_kost: 0 * kostCoefficient }),
        //   car: 'Opel Astra',
        //   ENUM_CAR: 'OPEL_ASTRA',
        //   src: '/img/opel-astra-sports-tourer.png',
        //   ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, extra_kost: 0 * kostCoefficient })),
        // },
        // {
        //   berekeningOptions: Object.assign({}, baseOptions, { extra_kost: 0 * kostCoefficient }),
        //   car: 'Opel Vivaro',
        //   ENUM_CAR: 'OPEL_VIVARO',
        //   src: '/img/opel-vivaro.png',
        //   ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, extra_kost: 0 * kostCoefficient })),
        // },
        // {
        //   berekeningOptions: Object.assign({}, baseOptions, { extra_kost: 9.43 * kostCoefficient }),
        //   car: 'Mercedes Vito',
        //   ENUM_CAR: 'MERCEDES_VITO',
        //   src: '/img/mercedes-vito.png',
        //   ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, extra_kost: 9.43 * kostCoefficient })),
        // },
        // maxPersonen <= 3 && {
        //   berekeningOptions: Object.assign({}, baseOptions, { extra_kost: 24.86 * kostCoefficient }),
        //   car: 'Mercedes E-Klasse',
        //   ENUM_CAR: 'MERCEDES_E_KLASSE',
        //   src: '/img/mercedes-e-klasse.png',
        //   ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, extra_kost: 24.86 * kostCoefficient })),
        // },
        // {
        //   berekeningOptions: Object.assign({}, baseOptions, { extra_kost: 29.77 * kostCoefficient }),
        //   car: 'Mercedes V-Klasse',
        //   ENUM_CAR: 'MERCEDES_V_KLASSE',
        //   src: '/img/mercedes-v-klasse.png',
        //   ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, extra_kost: 29.77 * kostCoefficient })),
        // },
        {
          berekeningOptions: Object.assign({}, baseOptions, { isPriveVervoer: false, extra_kost: 0 }),
          car: 'Gedeeld Vervoer',
          ENUM_CAR: null,
          info: 'Gecombineerd met andere reizigers',
          src: null,
          ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, isPriveVervoer: false, extra_kost: 0 })),
        },
        {
          berekeningOptions: Object.assign({}, baseOptions, { isPriveVervoer: true, extra_kost: 0 }),
          car: 'Gegarandeerd Privé Vervoer',
          ENUM_CAR: null,
          info: 'Gegarandeerd privé taxi',
          src: null,
          ...sharedLib.bereken(Object.assign({}, baseOptions, { isPrijsOnly: true, isPriveVervoer: true, extra_kost: 0 })),
        },
      ].filter((el) => !!el)
    })

    const selectedOption = computed(() => selectedOptionIndex?.value >= 0 ? oplossingen.value[selectedOptionIndex.value] : null)
    const showStep2 = computed(() => selectedOption.value && routeName.value === 'Reserveren')

    const calculatePrice = (step1Values) => {
      const data = Object.assign({}, step1Values.value || step1Values)
      form.value.is_retour = !!data.is_retour
      form.value.heenreis.personen = Math.max(1, Number(data.heenreis.personen))
      form.value.heenreis.datum = data.heenreis.datum
      form.value.heenreis.tijd = data.heenreis.tijd
      const adressenHeen = [data.heenreis.van, data.heenreis.naar]
      if (JSON.stringify(adressenHeen) !== JSON.stringify(form.value.heenreis.adressen)) {
        form.value.heenreis.adressen = adressenHeen
      }
      if (data.is_retour) {
        form.value.retour.personen = Math.max(1, Number(data.retour.personen))
        form.value.retour.datum = data.retour.datum
        form.value.retour.tijd = data.retour.tijd
        const adressenRetour = [data.retour.van, data.retour.naar]
        if (JSON.stringify(adressenRetour) !== JSON.stringify(form.value.retour.adressen)) {
          form.value.retour.adressen = adressenRetour
        }
      } else {
        form.value.retour.personen = 0
        form.value.retour.datum = null
        form.value.retour.tijd = null
        form.value.retour.adressen = []
      }
      form.value = Object.assign({}, form.value)
    }

    const retourAdressenVerschillend = computed(() => {
      if (!form.value.is_retour || !form.value.retour.adressen?.length) {
        return false
      }
      const heenKey = form.value.heenreis.adressen.map((el) => el.place_id).join('-')
      const retourKey = form.value.retour.adressen
        .map((el) => el.place_id)
        .reverse()
        .join('-')
      return heenKey !== retourKey
    })

    const onDirections = ({ id, distance, duration, warnings }) => {
      // console.log({ fn: 'onDirections', id, distance, duration, warnings, retourAdressenVerschillend: retourAdressenVerschillend.value })
      if (form.value[id]) {
        form.value[id].warnings = warnings || []
        form.value[id].kilometers = Math.max(0, (distance || 0) / 1000)
        form.value[id].seconden = Math.max(0, duration || 0)
      }
      if (id === 'heenreis' && retourAdressenVerschillend.value === false) {
        onDirections({ id: 'retour', distance, duration, warnings })
      }
    }

    const onOptionSelected = (index) => {
      selectedOptionIndex.value = index
      router.push('/reserveren')
    }

    const onSubmit = (_data) => {
      // form.value.prijs = selectedOption.value.prijs
      const data = clone(_data)
      data.prijs = selectedOption.value.prijs
      data.is_last_minute = selectedOption.value.berekeningOptions.isLastMinute
      data.is_prive_vervoer = selectedOption.value.berekeningOptions.isPriveVervoer
      data.extra_kost = selectedOption.value.berekeningOptions.extra_kost
      data.car = selectedOption.value.ENUM_CAR || null
      data.opmerking = `${data.opmerking ? `${data.opmerking}\n` : ''}Gekozen wagen: ${selectedOption.value.car}`
      api.reservaties.create(data).then(() => {
        router.replace('/gefeliciteerd')
      })
    }

    onMounted(() => {
      if (routeName.value === 'Reserveren' && !selectedOption.value) {
        router.replace('/')
      }
    })

    return { oplossingen, retourAdressenVerschillend, selectedOptionIndex, selectedOption, showStep2, form, onOptionSelected, calculatePrice, onDirections, onSubmit }
  },
}
</script>

<style lang="scss" scoped>
.form--wrapper {
  max-width: 1200px;
  margin: 10px auto;
  &.open {
    min-height: calc(100vh - 240px);
  }
}
.form--notice {
  text-shadow: 1px 1px darken(#1d0c50, 10%);
}
</style>
