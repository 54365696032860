<template>
  <form @submit.prevent="onSubmit" class="bg-baioro-black p-2">
    <div class="p-2" :class="{ 'pt-0': form.is_retour }" v-for="n in form.is_retour ? 2 : 1" :key="n">
      <h2 class="flex-1 block font-bold" v-if="form.is_retour">{{ n === 1 ? 'Heenreis' : 'Retour' }}</h2>
      <div class="flex flex-col xl:flex-row gap-2">
        <div class="flex-1 w-full">
          <label for="">Ophaallocatie</label>
          <input
            :ref="(el) => (inputValues[n === 1 ? 'heenreis' : 'retour'].van.input = el)"
            v-model="inputValues[n === 1 ? 'heenreis' : 'retour'].van.text"
            @keypress="onChange(form[n === 1 ? 'heenreis' : 'retour'].van)"
            @blur="onBlur(inputValues[n === 1 ? 'heenreis' : 'retour'].van, form[n === 1 ? 'heenreis' : 'retour'].van)"
            type="text"
            class="input w-full"
          />
        </div>
        <div class="flex-1 w-full">
          <label for="">Bestemming</label>
          <input
            :ref="(el) => (inputValues[n === 1 ? 'heenreis' : 'retour'].naar.input = el)"
            v-model="inputValues[n === 1 ? 'heenreis' : 'retour'].naar.text"
            @keypress="onChange(form[n === 1 ? 'heenreis' : 'retour'].naar)"
            @blur="onBlur(inputValues[n === 1 ? 'heenreis' : 'retour'].naar, form[n === 1 ? 'heenreis' : 'retour'].naar)"
            type="text"
            class="input w-full"
          />
        </div>
        <div>
          <label for="" class="block">Personen</label>
          <select class="input px-0 w-auto" @input="onChange()" v-model="form[n === 1 ? 'heenreis' : 'retour'].personen">
            <option v-for="n in 50" :key="n" :value="n" :selected="form[n === 1 ? 'heenreis' : 'retour'].personen === n">{{ n }} {{ n == 1 ? 'persoon' : 'personen' }}</option>
          </select>
        </div>

        <div>
          <label for="">Datum &amp; tijd</label>
          <div class="flex flex-row gap-2">
            <input type="date" id="start" name="trip-start" @input="onChange()" v-model="form[n === 1 ? 'heenreis' : 'retour'].datum" class="input z-0 dark" min="2022-01-01" />
            <input
              type="time"
              id="start"
              name="trip-start"
              @input="onChange()"
              v-model="form[n === 1 ? 'heenreis' : 'retour'].tijd"
              class="input text-center pl-4 p-0"
              min="2022-01-01"
            />
          </div>
        </div>
      </div>
      <div class="block w-full mt-1">
        <span class="inline-flex flex-row whitespace-nowrap items-center justify-center content-center" v-if="n === 1">
          <input type="checkbox" class="text-baioro-gold" @input="onChange()" v-model="form.is_retour" />
          <span class="pl-1">Ook retour nodig?</span>
        </span>
      </div>
      <div class="flex w-full justify-center items-center">
        <button
          :disabled="gezocht || errorString"
          v-if="n === (form.is_retour ? 2 : 1)"
          :class="{ 'w-full lg:w-36 mt-2 lg:-mt-4': n === 1, 'w-full lg:w-40 mt-1': n === 2 }"
          class="py-1"
          type="submit"
        >
          Zoeken
        </button>
      </div>
    </div>
  </form>

  <div v-if="error" class="bg-red-100 text-red-800 border border-red-800 rounded mt-1 p-2 mx-4">{{ error }}</div>
  <!-- <pre>{{ { a: inputValues.heenreis.van.text } }}</pre>
  <pre>{{ { b: inputValues.heenreis.naar.text } }}</pre>
  <pre>{{ { c: inputValues.retour.van.text } }}</pre>
  <pre>{{ { d: inputValues.retour.naar.text } }}</pre>
  <pre>{{ form }}</pre> -->
</template>

<script>
import { nextTick, onMounted, ref, watch, computed } from 'vue'
import useGoogleMaps from '@/hooks/useGoogleMaps'

const DEBUG = process.env.NODE_ENV === 'development'

export default {
  emits: ['submit', 'change'],
  setup(_, { emit }) {
    // const in3Days = new Date(Date.now() + 259200000)
    // const getDateString = (d) => [d.getFullYear(), d.getMonth() + 1, d.getDate()].map((el) => (el > 9 ? el : `0${el}`)).join('-')
    const inputValues = ref({
      heenreis: {
        van: {
          text: DEBUG ? 'Meeuwenlaan 100, 1800 Vilvoorde' : null,
          autocomplete: null,
          input: null,
        },
        naar: {
          text: DEBUG ? 'Plankenstraat 130, 1701 Dilbeek' : null,
          autocomplete: null,
          input: null,
        },
      },
      retour: {
        van: {
          text: null,
          autocomplete: null,
          input: null,
        },
        naar: {
          text: null,
          autocomplete: null,
          input: null,
        },
      },
    })

    const form = ref({
      heenreis: {
        van: !DEBUG
          ? null
          : {
              adres: 'Meeuwenlaan 100, 1800 Vilvoorde',
              is_luchthaven: false,
              administrative_area_level_1: 'Vlaams Gewest',
              administrative_area_level_2: 'Vlaams-Brabant',
              country: 'België',
              locality: 'Vilvoorde',
              place_id:
                'EipQYXBlZ2FhaXN0cmFhdCAxMCwgMTgwMCBWaWx2b29yZGUsIEJlbGdpdW0iUBJOCjQKMgk3PKSficLDRxFw7vSumBMotBoeCxDuwe6hARoUChIJbxkCZP_nw0cRiA3OInWlm7MMEAoqFAoSCTc8pJ-JwsNHEakH-VfORbnQ',
              postal_code: '1800',
              route: 'Meeuwenlaan',
              street_number: '100',
              sublocality: 'Het Voor',
              sublocality_level_1: 'Het Voor',
            },
        naar: !DEBUG
          ? null
          : {
              adres: 'Plankenstraat 130, 1701 Dilbeek',
              is_luchthaven: false,
              administrative_area_level_1: 'Vlaams Gewest',
              administrative_area_level_2: 'Vlaams-Brabant',
              country: 'België',
              locality: 'Dilbeek',
              place_id: 'ChIJZaslLVvHw0cRipj-it9Ed_A',
              postal_code: '1701',
              route: 'Plankenstraat',
              street_number: '130',
            },
        personen: 1,
        datum: null,
        tijd: '13:00',
      },
      is_retour: false,
      retour: {
        van: null,
        naar: null,
        personen: 1,
        datum: null,
        tijd: '13:00',
      },
    })

    const gezocht = ref(false)
    const error = ref(null)

    watch(
      () => JSON.stringify(form.value),
      () => {
        error.value = null
        gezocht.value = false
        emit('change', form.value)
      },
    )

    const errorString = computed(() => {
      const data = form.value
      if (!data.heenreis.van?.place_id || !data.heenreis.naar?.place_id) {
        return 'Gelieve een ophaallocatie en bestemming op te geven.'
      }
      // if (!data.heenreis.van?.route || !data.heenreis.naar?.route) {
      //   error.value = 'Gelieve een volledig adres (met straat) als ophaallocatie en bestemming op te geven.'
      //   return
      // }
      const heenJaar = new Date(data.heenreis.datum).getFullYear()
      if (!data.heenreis.tijd || !data.heenreis.datum || heenJaar > 2075 || heenJaar < new Date().getFullYear()) {
        return 'Gelieve een datum en tijd op te geven.'
      }
      if (data.is_retour) {
        if (!data.retour.van?.place_id || !data.retour.naar?.place_id) {
          return 'Gelieve een ophaallocatie en bestemming op te geven.'
        }
        // if (!data.retour.van?.route || !data.retour.naar?.route) {
        //   return 'Gelieve een volledig adres (met straat) als ophaallocatie en bestemming op te geven.'
        // }
        const retourJaar = new Date(data.retour.datum).getFullYear()
        if (!data.retour.tijd || !data.retour.datum || retourJaar > 2075 || retourJaar < new Date().getFullYear()) {
          return 'Gelieve een datum en tijd op te geven.'
        }
      }

      return null
    })

    const onSubmit = (event) => {
      const error = errorString.value
      if (error) {
        error.value = error
        return
      }
      gezocht.value = true
      return emit('submit', form.value)
    }

    function fillInAddress(setText, setValue) {
      return function handleAddress() {
        // Get the place details from the autocomplete object.
        const place = this.getPlace()
        if (!place?.address_components) return
        const parts = place.address_components.reduce((result, item) => {
          if (!item.long_name || !item?.types?.length) {
            return result
          }
          item.types.forEach((type) => {
            result[type] = item.long_name
          })
          return result
        }, {})
        delete parts.political
        parts.place_id = place.place_id
        parts.adres = place.formatted_address
        parts.is_luchthaven = place?.types.includes('airport')
        setValue(parts)
        setText(place.formatted_address)
      }
    }

    const setupInputs = async () => {
      const google = await useGoogleMaps()
      const refsInput = [inputValues.value.heenreis.van, inputValues.value.heenreis.naar, inputValues.value.retour.van, inputValues.value.retour.naar]
      const refsForm = [(v) => (form.value.heenreis.van = v), (v) => (form.value.heenreis.naar = v), (v) => (form.value.retour.van = v), (v) => (form.value.retour.naar = v)]
      refsInput.forEach((refInput, index) => {
        if (!refInput.input || refInput.autocomplete) return
        refInput.autocomplete = new google.maps.places.Autocomplete(refInput.input, {
          // componentRestrictions: {},
          fields: ['place_id', 'types', 'formatted_address', 'address_components'],
          // types: ['establishment'],
        })
        refInput.autocomplete.addListener(
          'place_changed',
          fillInAddress(
            (v) => {
              refInput.text = v
            },
            (v) => {
              refsForm[index](v)
            },
          ),
        )
      })
      inputValues.value[form.value.is_retour ? 'retour' : 'heenreis'].van.input.focus()
    }

    onMounted(setupInputs)
    watch(
      () => form.value.is_retour,
      (val, oldval) => {
        if (val === oldval) return
        inputValues.value.retour.van.text = null
        inputValues.value.retour.naar.text = null
        inputValues.value.retour.van.autocomplete = null
        inputValues.value.retour.naar.autocomplete = null
        inputValues.value.retour.van.input = null
        inputValues.value.retour.naar.input = null
        form.value.retour.van = null
        form.value.retour.naar = null
        if (val) {
          form.value.retour.van = form.value.heenreis.naar
          form.value.retour.naar = form.value.heenreis.van
          form.value.retour.personen = form.value.heenreis.personen
          // const heenDate = new Date(form.value.heenreis.datum || in3Days.getTime())
          // form.value.retour.datum = getDateString(new Date(heenDate.getTime() + 86400000))
          inputValues.value.retour.van.text = inputValues.value.heenreis.naar.text
          inputValues.value.retour.naar.text = inputValues.value.heenreis.van.text
          setupInputs()
        }
      },
    )

    // const stopPropagation = () => {}
    // const preventDefault = () => {}
    const onBlur = async (inputRef, formRef) => {
      await nextTick()
      if (inputRef.text && !formRef) {
        inputRef.text = null
      }
    }

    const onChange = (formRef) => {
      // eslint-disable-next-line
      if (typeof formRef !== 'undefined') {
        formRef = null
      }
      emit('change', form.value)
    }

    return { form, inputValues, gezocht, error, errorString, onBlur, onChange, onSubmit }
  },
}
</script>

<style>
.pac-container {
  min-width: 300px !important;
}
</style>
